var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.makingApiRequest),expression:"makingApiRequest"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Save',
    key: 'saveNewQuoteCountyValidation',
    disabled: !_vm.validRequiredFields,
    loading: _vm.makingApiRequest
  },"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem,"errorMessageClosed":function($event){_vm.errorMessage = ''},"successMessageClosed":function($event){_vm.successMessage = ''}}},[_c('div',{staticClass:"mb-8 md:mb-12 flex justify-between"},[_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Quote Validation Details ")]),_c('p',{staticClass:"mt-1 text-sm text-gray-500"},[_vm._v(" Provide some information about the quote validation. ")])])]),_c('form-builder',{ref:"formElement",attrs:{"dynamicOptions":{
      companyNumbers: _vm.companyOptions,
      formTypes: _vm.formTypesOptions
    },"formValueDefaults":_vm.formValueDefaults,"formValues":_vm.editing,"schemas":_vm.quoteCountyValidationsSchema},on:{"formFieldChanged":_vm.formFieldChangedHandler}}),_c('ModalBase',{attrs:{"name":"allowOrBlock","clickToClose":false,"showClose":true,"allowYScrollOnOverflow":true,"bottomButtonPrimary":{
      key: 'allow',
      label: 'Allow'
    },"bottomButtonSecondary":{
      key: 'block',
      label: 'Block'
    },"title":"Condition choice","description":"Would you like to allow or block rating based on these conditions?","position":"center"},on:{"primaryButtonClick":_vm.allowRating}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }