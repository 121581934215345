
import {
  quoteCountyValidationMapActions,
  quoteCountyValidationMapMutations
} from "@/store/modules/quoteCountyValidation";

import { SET_EDIT_FIELD } from "@/store/mutation-types";
import VueWithMixins from "@/helpers/mixins";
import QuoteCountyValidationMixin from "./QuoteCountyValidationMixin";

export default VueWithMixins(QuoteCountyValidationMixin).extend({
  name: "quoteCountyValidation",
  created() {
    this.SET_EDIT({} as any);
  },
  mounted() {
    this.$modal.show("allowOrBlock");
  },
  computed: {
    validRequiredFields(): boolean {
      return !!(
        this.editing?.companyNumbers.length &&
        this.editing?.validationMessage &&
        this.editing?.effectiveDate &&
        this.editing?.title &&
        this.hasAtLeastOneRequiredField
      );
    }
  },
  methods: {
    async submit(): Promise<void> {
      try {
        this.loadingText = "Creating quote validation. Please wait...";
        this.isLoading = true;
        await this.createQuoteCountyValidation(this.editing);
        this.$router.replace("/admin/quoteCountyValidations");
        this.SET_EDIT({} as any);
        this.$appNotifySuccess("County validation created");
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "saveNewQuoteCountyValidation":
          await this.submit();
          break;
      }
    },
    allowRating() {
      this.editField({ key: "allowRating", value: true });
      this.$modal.hide("allowOrBlock");
    },
    ...quoteCountyValidationMapActions(["createQuoteCountyValidation"]),
    ...quoteCountyValidationMapMutations({
      editField: SET_EDIT_FIELD
    })
  }
});
